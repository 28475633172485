import React, { useEffect, useMemo, useState } from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/course-items.png";
import Navigation from "../components/common/webinars/Navigation";
import University from "../components/university-list-templates/University";
import { graphql } from "gatsby";

const UniversityListPage = ({ data, pageContext }) => {
  const {
    title,
    metaTitle,
    metaKeywords,
    metaImage,
    // h1,
    cta,
    freeItems,
    metaDescription,
    slug,
    navigationItems,
  } = pageContext;

  const [getPlayerState, setPlayerState] = useState({
    isShowYoutubeModal: false,
    urlYoutubeModal: "",
  });

  const updatePlayerState = (key, value) => {
    setPlayerState((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    updatePlayerState("isShowYoutubeModal", !!getPlayerState.urlYoutubeModal);
  }, [getPlayerState.urlYoutubeModal]);

  const closeYoutubeModal = () => updatePlayerState("urlYoutubeModal", "");

  useEffect(() => {
    const handleKeyEsc = (e) => {
      if (e.keyCode === 27) {
        closeYoutubeModal();
      }
    };
    document.addEventListener("keydown", handleKeyEsc);

    return () => {
      document.removeEventListener("keydown", handleKeyEsc);
    };
  }, []);

  const navigationPanel = useMemo(
    () => (
      <Navigation
        mainTitle={cta}
        items={navigationItems}
        currentPage={slug}
        isBlueView
      />
    ),
    []
  );
  return (
    <Layout
      urlYoutubeModal={getPlayerState.urlYoutubeModal}
      isShowYoutubeModal={getPlayerState.isShowYoutubeModal}
      closeYoutubeModal={closeYoutubeModal}
      isAbsoluteHeader
    >
      <Seo
        title={title}
        metaTitle={metaTitle}
        keywords={metaKeywords}
        description={metaDescription}
        page={slug}
        manifest="browserconfig.xml"
        ogImg={metaImage ? metaImage.file.url : OgImg}
      />
      {navigationPanel}
      <University
        listOrder={freeItems}
        items={data.allContentfulUniversityItem.edges}
        updatePlayerState={updatePlayerState}
        isWaMode={false}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query universityListPageQuery {
    allContentfulUniversityItem {
      edges {
        node {
          title
          id
          description {
            raw
          }
          person {
            nameSurname
            videoUrl
            photo {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
            description {
              raw
            }
          }
          pageRef {
            slug
          }
          price
          discountPrice
          alternativePersonName
          alternativePersonDescription {
            raw
          }
        }
      }
    }
  }
`;

export default UniversityListPage;
