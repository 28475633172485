import React, { useEffect, useMemo } from "react";
import Container from "../../common/Container";
import "./index.scss";
import UniversityItem from "./UniversityItem";
import PropTypes from "prop-types";

const University = ({
  items,
  listOrder,
  updatePlayerState,
  isWaMode,
  heightPage,
  handleRouteChange,
  onMount,
}) => {
  useEffect(() => {
    onMount && onMount();
  }, []);

  const getItemsFromQuery = () => {
    return (
      listOrder.length > 0 &&
      listOrder.map((objId, index) => {
        const item = items.find((e) => e.node.id === objId.id && !e.node.paid);
        return (
          <UniversityItem
            data={item.node}
            key={index}
            updatePlayerState={updatePlayerState}
            isWaMode={isWaMode}
            heightPage={heightPage}
            handleRouteChange={handleRouteChange}
          />
        );
      })
    );
  };

  const freeCourses = useMemo(
    () => getItemsFromQuery(),
    [isWaMode, heightPage]
  );

  return (
    <section className="University">
      <Container webSize>{freeCourses}</Container>
    </section>
  );
};
University.prototype = {
  items: PropTypes.object.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  listOrder: PropTypes.array.isRequired,
  heightPage: PropTypes.number,
  handleRouteChange: PropTypes.func,
  onMount: PropTypes.func,
};

export default University;
